import React, { Component } from "react";
import PropTypes from "prop-types";

import NotificationItem from "./NotificationItem";
import styled from "styled-components";
import close from "../../../svg/close.svg";

const CSS_HIDE_ANIMATION_TIME = 600; // this must be equal or higher then transition time of hidding animation
const DEFAULT_DISMISS_DELAY = 2000;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  & span {
    flex: 1;
  }
`;

const XButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: right;
  color: #fff;

  & img {
    height: 0.8rem;
  }
`;

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification: null,
      status: "invisible"
    };
  }

  componentDidMount() {
    this.showNext(this.props);
  }

  componentWillUnmount() {
    clearTimeout(this.showingTimeout);
    clearTimeout(this.hiddingTimeout);
    clearTimeout(this.removeTimeout);
  }

  componentDidUpdate() {
    if (this.props.notifications && this.props.notifications.length) {
      this.showNext(this.props);
    }
  }

  showNext(props) {
    const notification =
      props.notifications && props.notifications.length
        ? props.notifications[0]
        : null;

    if (!this.state.notification && notification) {
      this.setState({
        notification,
        status: "invisible"
      });

      this.showingTimeout = setTimeout(() => {
        this.setState({
          status: "showing"
        });
      }, 0);

      if (!notification?.isDismissable) {
        this.planHide(notification);
        props.clearDisplayedNotification(notification.id);
      }
    }
  }

  dismissNotification(notificationId) {
    this.props.clearDisplayedNotification(notificationId);
    this.setState({
      notification: null,
      status: "invisible"
    });
  }

  planHide(notification) {
    this.hiddingTimeout = setTimeout(() => {
      this.setState({
        status: "hiding"
      });

      this.removeTimeout = setTimeout(() => {
        this.setState({
          notification: null
        });

        this.showNext(this.props);
      }, CSS_HIDE_ANIMATION_TIME);
    }, notification.dismissDelay || DEFAULT_DISMISS_DELAY);
  }

  render() {
    const { notification, status } = this.state;

    return (
      notification && (
        <NotificationItem
          type={notification.type}
          status={status}
          fluid={this.props.fluid}
          data-testid="notification-item"
        >
          <Container>
            <span>{notification.message}</span>
            {notification?.isDismissable && (
              <XButton
                onClick={() => this.dismissNotification(notification?.id)}
              >
                <img src={close} alt="Close" />
              </XButton>
            )}
          </Container>
        </NotificationItem>
      )
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      type: PropTypes.oneOf(["error", "success", "info"])
    })
  ),
  clearDisplayedNotification: PropTypes.func.isRequired
};

export default Notifications;
