import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import COLORS from "../../../constants/colors";
import { NAVBAR_HEIGHT } from "../Navbar2/NavbarConstants";

const NOTIFICATION_MIN_HEIGHT = 50;

const typeToColor = type => {
  switch (type) {
    case "success":
      return COLORS.STATUS_COLORS.SUCCESS;
    case "error":
      return COLORS.STATUS_COLORS.ERROR;
    default:
      return COLORS.STATUS_COLORS.INFO;
  }
};

// "invisible" => top=-height, visibility=hidden
// "showing" => top=0, visibility=visible
// "hiding" => top=-height;  visibility=visible
const computeTop = (status, height, fluid) => {
  const top =
    status === "showing" ? (fluid ? `${NAVBAR_HEIGHT}` : 0) : `-${height}`;
  return `${top}px`;
};

const Notification = styled.div`
  align-self: center;
  visibility: hidden;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 0 3px 3px;
  color: #ffffff;
  text-align: center;

  padding: 16px;
  position: ${props => (props.fluid ? "fixed" : "relative")};
  font-size: 16px;
  min-height: ${NOTIFICATION_MIN_HEIGHT}px;
  line-height: 1;

  box-sizing: border-box;
  z-index: ${props => (props.fluid ? 2 : 1)};
  transition: top 0.5s;

  background-color: ${props => typeToColor(props.type)};
  border-color: ${props => typeToColor(props.type)};
  visibility: ${props => (props.status === "invisible" ? "hidden" : "visible")};
  top: ${props => computeTop(props.status, props.height, props.fluid)};

  ${props =>
    props.fluid &&
    css`
      border-radius: 0;
      width: 100%;
    `};
`;

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);
    this.notificationRef = React.createRef();

    this.state = {
      height: NOTIFICATION_MIN_HEIGHT
    };
  }

  componentDidMount() {
    const { height } = this.notificationRef.current.getBoundingClientRect();
    this.setState({ height });
  }

  render() {
    return (
      <Notification
        {...this.props}
        ref={this.notificationRef}
        height={this.state.height}
      />
    );
  }
}

NotificationItem.defaultProps = {
  type: "info",
  status: "invisible",
  fluid: false
};

NotificationItem.propTypes = {
  status: PropTypes.oneOf(["invisible", "showing", "hiding"]),
  type: PropTypes.oneOf(["error", "success", "info"]),
  fluid: PropTypes.bool
};

export default NotificationItem;
